/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.borderline {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px;
}

.borderCorner {
  border-radius: 4px;
}

.whiteBackground{
  background-color:#fff;
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  src: local('Quicksand-SemiBold'), url(./font/Quicksand-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Light';
  src: local('Quicksand-Light'), url(./font/Quicksand-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Medium';
  src: local('Quicksand-Medium'), url(./font/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Regular';
  src: local('Quicksand-Regular'), url(./font/Quicksand-Regular.ttf) format('truetype');
}