.rbc-public-date {
    color: red;
}

.rbc-normal-date {
    color: black;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #ddd;
}

.rbc-date-cell {
    flex: 1 1;
    min-width: 0;
    padding-right: 0;
    text-align: right;
}

.rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    background-color: unset;
    width: 100%;
    text-align: left;
    padding: 0;
    cursor: unset;
}

.rbc-off-range-bg {
    background: #f1f1f1;
}

.rbc-event.rbc-selected {
    background-color: unset;
}

.rbc-event:focus {
    outline: none;
}

.rbc-selected-cell {
    background-color: #ffefe2 !important;
    width: 100%;
    height: 100%;
    z-index: unset !important;
}

.rbc-today {
    height: 100%;
    border: 2px solid rgb(0, 100, 231);
    position: relative;
    z-index: 5;
    background-color: unset;
    pointer-events: none;
}

.otherMonth .rbc-today {
    border: 0 !important;
}

.rbc-off-range-bg .rbc-selected-cell {
    background-color: unset !important;
}
.rbc-show-more{
    color: #000
}
.rbc-show-more:hover{
    text-decoration: none;
    color: #000
}
