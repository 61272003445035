@font-face {
    font-family: 'text-security-disc';
    src: url('text-security-disc-compat.eot');
    src: url('text-security-disc-compat.eot?#iefix') format('embedded-opentype'), url('text-security-disc-compat.woff2') format('woff2'), url('text-security-disc-compat.ttf') format('truetype')
}

@supports((content-visibility:visible) or (-moz-appearance:meter)) and (not (-webkit-hyphens:none)) {
    @font-face {
        font-family: 'text-security-disc';
        src: url('text-security-disc.woff2') format('woff2'), url('text-security-disc-compat.ttf') format('truetype')
    }
}