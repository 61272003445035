::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #d8d8d8;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background: unset;
}

div,
html,
body {
    scrollbar-color: #d8d8d8 white;
    scrollbar-width: thin;
    font-family: "Quicksand-SemiBold", sans-serif;
}

button:focus {
    outline: 0px;
}